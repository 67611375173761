import React, { Fragment } from "react";

import Page from "lib/@cms/components/layout/Page";
import PageHeader from "lib/@cms/components/cms/PageHeader";
import Text from "lib/@cms/components/shared/Text";
import Feature from "lib/@cms/components/cms/Feature";
import Divider from "lib/@cms/components/primitive/Divider";

function PayBill() {
  return (
    <Page pathname="pay-bill">
      {data => {
        return (
          <React.Fragment>
            <PageHeader
              variant={PageHeader.variant.GRADIENTS}
              color={PageHeader.color.PRIMARY}
              data={data.PageHeaderGradients}
            />
            <Divider className="tw-mb-4" />

            <div className="tw-container">
              <Text variant={Text.variant.BODY}>{data.MetaData.description}</Text>

              {data.FeaturesV2?.map((feature, index) => {
                return (
                  <Fragment key={`FeatureV2-${index}`}>
                    <Divider className="tw-mb-20 md:tw-mb-40" />
                    <Feature variant={Feature.variant.V2} data={feature} />
                  </Fragment>
                );
              })}
              <Divider className="tw-mb-16 md:tw-mb-40" />
            </div>
          </React.Fragment>
        );
      }}
    </Page>
  );
}

export default PayBill;
